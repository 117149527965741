@import '@/shared/variables';

.main {
  width: 100%;
  min-height: 100vh;
  background-image: url(../shared/images/backRotoplas@2x.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.main::before {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  content: '';
  opacity: 0.3;
  z-index: 2;
}

// section {
//   width: 100%;
//   height: 100vh;
//   position: absolute;

// }

.invalid {
  border: 1px solid $color-red !important;
  font-size: 12px;
}

.invalid::placeholder {
  color: $color-red !important;
  font-size: 12px;
}

.modal-login {
  position: relative;
  width: 80%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 5;
  column-gap: 100px;
}

.modal-login h1 {
  font-size: 2rem;
  color: white;
}

.information {
  width: 417px;
}

.title h1 {
  color: white;
  font-size: 36px;
}

.custom-content p {
  color: white;
}

.login {
  width: 400px;
  background-color: white;
  text-align: center;
  padding: 60px 10px;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
}

// grid

.grid {
  display: grid;
  width: 300px;
  height: 570px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'title login'
    'custom-content login';
  justify-content: center;
}

.title {
  grid-area: title;
  width: 510px;
  align-self: end;
}

.login {
  grid-area: login;
}

.custom-content {
  grid-area: custom-content;
  width: 500px;
  margin-top: 0px;
}

@media screen and (max-width: 956px) {
  .grid {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'login'
      'custom-content';
  }

  .modal-login {
    height: 65%;
  }

  .login {
    margin-left: 15px;
  }

  .title h1 {
    text-align: center;
  }

  .main {
    background-image: url(../shared/images/backRotoplas.png);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }

  .information {
    width: 100%;
    margin-bottom: 20px;
  }

  .box-inputs {
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: start;
    margin: 20px auto;
    font-weight: bolder;
  }

  .grid {
    height: 100%;
  }

  .login {
    width: 100%;
    margin-left: 0;
  }

  .title {
    width: 100%;
  }

  .custom-content {
    width: 100%;
    margin-left: 0;
    margin-top: 25px;
  }
}

// grid end

.login h3 {
  margin: 20px 0;
  font-size: 1.6rem;
  color: $color-blue-dark;
  font-weight: bolder;
}

.box-image {
  width: 60%;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
}

.box-image img {
  width: 300;
  height: 70;
}

.login form {
  width: 100%;
}

.box-inputs {
  display: flex;
  flex-direction: column;
  width: 85%;
  text-align: start;
  margin: 20px auto;
  font-weight: bolder;
}

.box-inputs input {
  border-radius: 37px;
  border: 1px solid $color-gray-light;
  font-size: 1rem;
  padding: 8px 10px;
  color: #616161;
  font-weight: normal;
  font-size: 12px;
}

.box-inputs label {
  margin-bottom: 10px;
  color: $color-blue-dark;
  font-size: 12px;
}

.form .button-login {
  width: 85%;
  padding: 20px 10px;
  border-radius: 33px;
  background-color: $color-blue-light;
  color: white;
  font: normal normal bold 14px/20px;
}

.form a {
  color: $color-blue-light;
}

.footer {
  height: $footer-height;
}
